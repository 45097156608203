
import Vue from "vue"
import { aclWrapper } from "@/utilities/aclWrapper"
import { Store } from "@/store"
import { Client, AclExtract } from "@/types/responses"

const CustomerInfo = () => import("@/components/customer/CustomerInfo.vue").then(m => m.default || m)

const ClientsList = () => import("@/components/customer/ClientsList.vue").then(m => m.default || m)
//const ClientInvitations = () => import("@/components/customer/ClientInvitations.vue").then(m => m.default || m)
const PersonsList = () => import("@/components/customer/PersonsList.vue").then(m => m.default || m)

import { CustomerEnum, ClientEnum, LinkOnboardingEnum } from "@/types/enums"
import { Route } from "vue-router"

export default Vue.extend({
	name: "Customer",
	data(): {
		customerenm: typeof CustomerEnum
		clientenm: typeof ClientEnum
        onboardingenm: typeof LinkOnboardingEnum
		currentModulePath: CustomerEnum.CustomerPersons
		parentModulePath: string
		parentName: string
        // eslint-disable-next-line
        tab: null | Record<string, any>
        // eslint-disable-next-line
        customerTabs: null | Record<string, any>
        minHeight: number | string
	} {
		return {
			customerenm: CustomerEnum,
			clientenm: ClientEnum,
            onboardingenm: LinkOnboardingEnum,
			currentModulePath: CustomerEnum.CustomerPersons,
			parentModulePath: this.$router.currentRoute.path.split("/")[1],
			parentName: this.$router.currentRoute.name ? this.$router.currentRoute.name : "",
            tab: null,
            customerTabs: null,
            minHeight: "100%"
		}
	},
	components: {
		CustomerInfo
	},
	watch: {
		$route(to: Route, from: Route) {
			this.parentModulePath = to.path.split("/")[1]
			this.parentName = to.name ? to.name : ""
		},
        customerName: {
            deep: true,
            handler(){
                console.log("updated selected customer (Customer.vue)")
            }
        }
	},
    mounted(){
        // eslint-disable-next-line
        this.customerTabs = (this.$refs as any).customerTabs;
        this.setTabsNavHeight()
    },
    computed: {
		getAcl(): AclExtract {
			if(this.$route.name?.includes(this.customerenm.Customer)){
				return this.$store.state.acl[this.customerenm.Customer]
			}
			return this.$store.state.acl[this.clientenm.ClientOrganization]
		},
        hasLinkOnboardingModuleAcl(): boolean {
            console.log(this.$store.state.acl[this.customerenm.Customer].modules.includes(LinkOnboardingEnum.LinkOnboarding))
            if(this.$route.name?.includes(this.customerenm.Customer)){
                return this.$store.state.acl[this.customerenm.Customer].modules.includes(LinkOnboardingEnum.LinkOnboarding);
			}
            return false
        },
        getOnboardingAcl(): AclExtract {
            return this.$store.state.acl[this.onboardingenm.LinkOnboarding];
        },
		enm(): typeof CustomerEnum | typeof ClientEnum {
			if(this.$route.name?.includes(this.customerenm.Customer)){
				return this.customerenm
			}

			return this.clientenm
		},
		linkOnboardingOverviewAcl(): boolean {
            return this.getOnboardingAcl && this.getOnboardingAcl.hasPermission
		},
        customerName(): string {
			if (this.$vStore.state.context.selectedCustomer) {
				return this.$vStore.state.context.selectedCustomer.name
			} else {
				return "Company"
			}
		},
        // eslint-disable-next-line
        tabContent(): Array<any>{
            const tabs = [];
            tabs.push({
                    id: 1,
                    title: "EMPLOYEES",
                    subtitle: "Employees with full access",
                    component: PersonsList
                });
/*             if(this.hasLinkOnboardingModuleAcl){
                tabs.push({
                    id: 2,
                    title: "REGISTRATIONS",
                    component: aclWrapper(ClientInvitations, "LinkOnboarding")
                });
            } */
            tabs.push({
                    id: 2,
                    title: "CONTACTS",
                    subtitle: "External contacts and employees with limited access",
                    component: ClientsList
                });

            return tabs;
        }
	},
    methods: {
        setTabsNavHeight(): number | string {
            if(this.customerTabs == null) {
                return 0
            }
            else {
                // eslint-disable-next-line
                this.customerTabs.$children.forEach((tab: any) => {
                    if(
                        tab.$el.clientHeight >= this.minHeight || 
                        this.minHeight == undefined || 
                        this.minHeight == "100%" 
                    ){
                        this.minHeight = tab.$el.clientHeight;
                    }
                });
                return this.minHeight
            }
        }
    }
    
})
